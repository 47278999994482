var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("LandingHeader"),
      _c("FileUploadHero"),
      _c("div", { ref: "featuresSection", staticClass: "features-section" }, [
        _c("div", { staticClass: "features-col-form" }, [
          _vm._m(0),
          _c("div", { staticClass: "align-self-center ml-5" }, [
            _c("div", { staticStyle: { width: "450px" } }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.name,
                      expression: "form.name",
                    },
                  ],
                  staticClass: "form-control form-control-lg",
                  attrs: { type: "text", placeholder: "Name" },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "name", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email",
                    },
                  ],
                  staticClass: "form-control form-control-lg",
                  attrs: { type: "email", placeholder: "Email Address" },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "email", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(3),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.schoolDBN,
                      expression: "form.schoolDBN",
                    },
                  ],
                  staticClass: "form-control form-control-lg",
                  attrs: { type: "text", placeholder: "School DBN" },
                  domProps: { value: _vm.form.schoolDBN },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "schoolDBN", $event.target.value)
                    },
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "form-group" },
                _vm._l(_vm.form.files, function (file, idx) {
                  return _c(
                    "div",
                    {
                      key: `attachedFile_${idx}`,
                      staticClass: "px-3 py-1 my-2 d-flex align-items-center",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "kt-link kt-link--state kt-link--primary kt-font-bolder no-wrap",
                        },
                        [_vm._v(" " + _vm._s(file.name) + " ")]
                      ),
                      _c(
                        "a",
                        {
                          staticClass:
                            "kt-link kt-link--state kt-link--danger kt-font-bolder ml-auto",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.form.files = _vm.form.files.filter(
                                (f, i) => i !== idx
                              )
                            },
                          },
                        },
                        [_c("i", { staticClass: "flaticon2-cross" })]
                      ),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "form-group form-group-last" }, [
                _c("label", { staticClass: "kt-font-lg" }, [
                  _vm._v("Attach Files"),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "dropzone dropzone-default dropzone-brand dz-clickable",
                    on: {
                      dragover: function ($event) {
                        $event.preventDefault()
                      },
                      drop: _vm.uploaderDrop,
                      click: _vm.uploaderClick,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "dropzone-msg dz-message needsclick" },
                      [
                        _c("h3", { staticClass: "dropzone-msg-title" }, [
                          _vm._v(" Drop files here or click to upload. "),
                        ]),
                        _c("input", {
                          ref: "file",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", name: "file", multiple: "" },
                          on: { change: _vm.onChange },
                        }),
                        _c("span", { staticClass: "dropzone-msg-desc" }, [
                          _vm._v("Upload up to 10 files"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "kt-form__actions float-right mt-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-2",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.clearForm.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-bold",
                    class: {
                      "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                    },
                    attrs: { disabled: _vm.saving || !_vm.canSubmitForm },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.submitForm.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" Submit ")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("LandingFooter"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "feature-box" }, [
      _c("div", { staticClass: "col-one" }, [
        _c("img", {
          attrs: { src: "/images/landing/support-icons_1.png", alt: "option" },
        }),
        _c("h3", [_vm._v("File Uploader")]),
        _c("p", [
          _vm._v(
            "Upload files securely for our technical support team to assist you."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "kt-font-lg" }, [
      _c("i", { staticClass: "fa fa-user mr-2" }),
      _vm._v("Full Name "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "kt-font-lg" }, [
      _c("i", { staticClass: "fa fa-envelope mr-2" }),
      _vm._v("Email "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "kt-font-lg" }, [
      _c("i", { staticClass: "fa fa-id-card mr-2" }),
      _vm._v("DBN "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }