var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { ref: "heroSection", staticClass: "hero-section" }, [
      _c("div", { staticClass: "inner-hero" }, [
        _c("div", { ref: "heroText", staticClass: "hero-text" }, [
          _c("h1", [_vm._v("Secure File Upload")]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }