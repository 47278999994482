<template>
<div id="app">
    <!-- header new -->
    <LandingHeader />

    <!-- hero section -->
    <FileUploadHero />

    <!-- form -->
    <div ref="featuresSection" class="features-section">
        <div class="features-col-form">
            <div class="feature-box">
                <div class="col-one">
                    <img src="/images/landing/support-icons_1.png" alt="option">
                    <h3>File Uploader</h3>
                    <p>Upload files securely for our technical support team to assist you.</p>
                </div>
            </div>
            <div class="align-self-center ml-5">
                <div style="width: 450px;">
                    <div class="form-group">
                        <label class="kt-font-lg">
                            <i class="fa fa-user mr-2" />Full Name
                        </label>
                        <input
                            v-model="form.name"
                            type="text"
                            class="form-control form-control-lg"
                            placeholder="Name"
                        >
                    </div>
                    <div class="form-group">
                        <label class="kt-font-lg">
                            <i class="fa fa-envelope mr-2" />Email
                        </label>
                        <input
                            v-model="form.email"
                            type="email"
                            class="form-control form-control-lg"
                            placeholder="Email Address"
                        >
                    </div>
                    <div class="form-group">
                        <label class="kt-font-lg">
                            <i class="fa fa-id-card mr-2" />DBN
                        </label>
                        <input
                            v-model="form.schoolDBN"
                            type="text"
                            class="form-control form-control-lg"
                            placeholder="School DBN"
                        >
                    </div>
                    <div class="form-group">
                        <div
                            v-for="(file, idx) in form.files"
                            :key="`attachedFile_${idx}`"
                            class="px-3 py-1 my-2 d-flex align-items-center"
                        >
                            <span class="kt-link kt-link--state kt-link--primary kt-font-bolder no-wrap">
                                {{ file.name }}
                            </span>
                            <a
                                href="#"
                                class="kt-link kt-link--state kt-link--danger kt-font-bolder ml-auto"
                                @click.stop.prevent="form.files = form.files.filter((f, i) => i !== idx)"
                            >
                                <i class="flaticon2-cross" />
                            </a>
                        </div>
                    </div>
                    <div class="form-group form-group-last">
                        <label class="kt-font-lg">Attach Files</label>
                        <div
                            class="dropzone dropzone-default dropzone-brand dz-clickable"
                            @dragover.prevent
                            @drop="uploaderDrop"
                            @click="uploaderClick"
                        >
                            <div class="dropzone-msg dz-message needsclick">
                                <h3 class="dropzone-msg-title">
                                    Drop files here or click to upload.
                                </h3>
                                <input
                                    ref="file"
                                    type="file"
                                    name="file"
                                    multiple
                                    style="display: none;"
                                    @change="onChange"
                                >
                                <span class="dropzone-msg-desc">Upload up to 10 files</span>
                            </div>
                        </div>
                    </div>
                    <div class="kt-form__actions float-right mt-3">
                        <button
                            class="btn btn-secondary mr-2"
                            @click.stop.prevent="clearForm"
                        >
                            Cancel
                        </button>
                        <button
                            class="btn btn-primary btn-bold"
                            :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                            :disabled="saving || !canSubmitForm"
                            @click.stop.prevent="submitForm"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- footer-section -->
    <LandingFooter />
</div>
</template>

<script>
import async from 'async';
import { v4 as uuidv4 } from 'uuid';
import gsap from 'gsap';
import LandingHeader from '../components/landing/LandingHeader.vue';
import FileUploadHero from '../components/landing/FileUploadHero.vue';
import LandingFooter from '../components/landing/LandingFooter.vue';
import { publicUploadToAWS } from '../lib/uploads/util';
import * as network from '../network';

export default {
    name: 'LandingFileUpload',
    components: {
        LandingHeader,
        FileUploadHero,
        LandingFooter,
    },
    data() {
        return {
            form: {
                name: null,
                email: null,
                schoolDBN: null,
                files: [],
            },
            saving: false,
            recentlySubmitted: false,
        };
    },
    computed: {
        canSubmitForm() {
            const { form } = this;
            return form.name && form.email && form.schoolDBN && form.files.length;
        },
    },
    mounted() {
        // Intersection Observer to detect when element enters viewport
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Trigger animation when element enters viewport
                    this.animateHeading();
                    observer.unobserve(entry.target);
                }
            });
        });

        // Observe the hero section
        observer.observe(this.$refs.featuresSection);
    },
    methods: {
        animateHeading() {
            // GSAP Animation
            gsap.from(this.$refs.featuresCol, {
                duration: 1,
                y: 150,
                opacity: 0,
                delay: 1,
                ease: 'power1.inOut',
            });
        },
        uploaderClick() {
            const elem = this.$refs.file;
            if (!elem) return;
            elem.click();
        },
        uploaderDrop(e) {
            e.stopPropagation();
            e.preventDefault();
            const { files } = e.dataTransfer;
            if (!files || !files.length) return;

            this.form.files = [...this.form.files, ...files].slice(0, 10);
        },
        onChange(e) {
            const { files } = e.target;
            if (!files || !files.length) return;

            this.form.files = [...this.form.files, ...files].slice(0, 10);
            e.target.value = '';
        },
        clearForm() {
            this.form = {
                name: null,
                email: null,
                schoolDBN: null,
                files: [],
            };
        },
        submitForm() {
            const v = this;
            if (v.recentlySubmitted) {
                return v.showError('We got your last form! Please wait a moment before submitting the form again.');
            }
            if (v.saving || !v.canSubmitForm) return;
            v.saving = true;

            const { form } = v;
            const {
                name, email, schoolDBN, files
            } = form;

            const params = {
                name,
                email,
                schoolDBN,
                uploadId: uuidv4(),
            };

            async.auto({
                upload(next) {
                    const expiryLinks = [];

                    async.eachLimit(files, 3, (file, nextFile) => {
                        publicUploadToAWS(params, file, (err, results) => {
                            if (err) return nextFile(err);
                            const { expiryLink } = results;
                            expiryLinks.push(expiryLink);
                            nextFile();
                        });
                    }, (err) => {
                        if (err) return next(err);
                        next(null, expiryLinks);
                    });
                },
                notify: ['upload', (results, next) => {
                    const { upload } = results;
                    const body = `Secure Upload from ${name}<br><br>${email}<br>DBN: ${schoolDBN}<br><br>Uploaded File(s):<br><br>${upload.join('<br><br>')}`;

                    network.landing.emailSupport({ body: { email, subject: 'New Secure Uploads', message: body } }, next);
                }],
            }, 5, (err, results) => {
                v.saving = false;
                if (err) return v.showError(err);

                v.showNotification('Your files has been uploaded successfully!');
                v.clearForm();
                v.recentlySubmitted = true;
                setTimeout(() => {
                    v.recentlySubmitted = false;
                }, 10000);
            });
        },
    },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.features-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 90px;
  margin-top: 80px;

  /* background: #f5f5f5; */

  .features-col {
    width: 1200px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .feature-box {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      background: #ebefff;
      border-radius: 1em;
      box-shadow: 0 10px 20px #131a331e;
      margin: 20px;
      height: 400px;
      cursor: pointer;
      transition: 0.5s;

      .col-one {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h3 {
          text-align: center;
          font-size: 2em;
          font-weight: 700;
          margin-bottom: 20px;
        }

        p {
          text-align: center;
          font-size: 1.2em;
          font-weight: 500;
          color: #666;
          margin-bottom: 20px;
        }
        img {
          width: 80px;
          margin-bottom: 20px;
        }
      }
    }

    .feature-box-hover:hover {
        margin-top: -10px;
    }
  }

  .features-col-form {
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .feature-box {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      background: #ebefff;
      border-radius: 1em;
      box-shadow: 0 10px 20px #131a331e;
      margin: 20px;
      height: 480px;
      cursor: pointer;
      transition: 0.5s;
      width: 360px;

      .col-one {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h3 {
          text-align: center;
          font-size: 2em;
          font-weight: 700;
          margin-bottom: 20px;
        }

        p {
          text-align: center;
          font-size: 1.2em;
          font-weight: 500;
          color: #666;
          margin-bottom: 20px;
        }
        img {
          width: 80px;
          margin-bottom: 20px;
        }
      }
    }

    .feature-box-hover:hover {
        margin-top: -10px;
    }
  }
}

#bulb-option {
  width: 50px;
}

@media screen and (max-width: 1024px) {
  .features-section {
    .features-col {
      width: 100%;
      .feature-box {
        .col-one {
          h3 {
            font-size: 1.8em;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .features-section {
    .features-col {
      grid-template-columns: repeat(1, 1fr);
      .feature-box {
        flex-direction: column;
        padding: 20px;
        height: auto;
        .col-one {
          width: 100%;
          h3 {
            font-size: 2em;
          }
          img {
            width: 80px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .features-section {
    .features-col {
      flex-direction: column;
      .feature-box {
        flex-direction: column-reverse;
        padding: 20px;
        height: auto;
        .col-one {
          width: 100%;
          h3 {
            font-size: 2em;
          }
          img {
            width: 30%;
          }
        }
      }
    }
  }
}
</style>
